import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/user/list`,
    component: React.lazy(() => import("views/app-views/users/list")),
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/user/update/:id`,
    component: React.lazy(() => import("views/app-views/users/edit")),
  },
];
